import React from 'react';
import { Alert } from 'reactstrap';

const Login = () => {
  return (
    <div className="account account--not-photo">
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head">
            <h3 className="account__title">
              <span className="account__logo"> 끄투리오
                <span className="account__logo-accent"> 관리자 도구</span>
              </span>
            </h3>
            <h4 className="account__subhead subhead">오늘도 끄투리오에 도움을 주셔서 감사합니다.</h4>
          </div>

          <Alert color="danger" isOpen={true}>
            <div className="alert__content">
              <p style={{ fontSize: '13pt' }}>
                본 시스템에 접근 권한이 없습니다.
                <br/>로그인 후 이용해주세요.
              </p>
            </div>
          </Alert>
        </div>
      </div>
    </div>
  );
};

export default Login;
